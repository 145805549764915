import React from "react";
import "./styles.css";
import Nav from "./navbar";
import Row from "./row";
import * as data from "./data"; //database


export default function App() {
  return (
    <div className="App">
      {Nav()}
      <h2
        className="display-2"
        style={{
          fontSize: "32px",
          textAlign: "left",
          color: "#f3b00c",
          marginTop: "5px",
          paddingLeft: "15px",
          paddingRight: "25px"
        }}
      >
        Day 21
      </h2>
      {Row(1, data.data[0], data.data[4], data.data[8])}
      {Row(2, data.data[1], data.data[5], data.data[9])}
      {Row(3, data.data[2], data.data[6], data.data[10])}
      {Row(4, data.data[3], data.data[7], data.data[11])}
      {Row(5, data.data[12], data.data[13], data.data[14])}
      {Row(6, data.data[15], data.data[16], data.data[17])}
      {Row(7, data.data[18], data.data[19], data.data[20])}
      {Row(8, data.data[21], data.data[22], data.data[23])}
      {Row(9, data.data[24], data.data[25], data.data[26])}
      {Row(10, data.data[27], data.data[28], data.data[29])}
      {Row(11, data.data[30], data.data[31], data.data[32])}
      {Row(12, data.data[33], data.data[34], data.data[35])}
      {Row(13, data.data[36], data.data[37], data.data[38])}
      {Row(14, data.data[39], data.data[40], data.data[41])}
      {Row(15, data.data[42], data.data[43], data.data[44])}
      {Row(16, data.data[45], data.data[46], data.data[47])}
      {Row(17, data.data[48], data.data[49], data.data[50])}
    </div>
  );
}
