export let data = [
  '{"name": "Brijesh Kumar Bhaiya", "place": "Choolai, Chennai", "waNumber": "919841827966", "email": "bbhaiya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/13dd186e98f4f344c03ea0b7db27493784472cbb657b7f6d87851d9172d749c6/WhatsApp_Image_2021_05_09_at_22.01.48__1_.jpeg", "scribbleSpace": "Travel, Food and exploring new places / avenues are few which i enjoy.", "contribution": "Planning and implementation of an activity.", "intro": "Businessman"}',
  '{"name": "Shilpi", "place": "Mahalunge Pune", "waNumber": "918698342681", "email": "shilpi.kumari26@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e2663a655423b248802ad7aac0c0ffa1488f9b481f4804797e6c873020c3dc5b/1530612583325.jpg", "scribbleSpace": "Doing yoga, meditation, staying fit, singing, writing, sketching", "contribution": "Experience of waking up early", "intro": "Singer"}',
  '{"name": "Poorvi Sharma", "place": "Pune", "waNumber": "919167335120", "email": "poorvi.sharma31@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6c4f8936321c71f51a870e9daedd6d0bb241a410d014611a75d1364fb87b7838/20210620_185318.jpg", "scribbleSpace": "When away from work, I enjoy abstract painting and find immense pleasure in bringing her imaginative senses to the canvas.", "contribution": "Haven\'t thought through, but can teach abstract painting.", "intro": "Product Marketing Person, Painter"}',
  '{"name": "Snehal Bari", "place": "Handewadi, Pune", "waNumber": "919284761691", "email": "barisnehal29@gmail.com", "photoLine": "https://api.typeform.com/responses/files/277d683334e71b002528583642b1fa984d6e3811df5c6e5b3eac073a258dfffa/IMG_20210625_175513_087.jpg", "scribbleSpace": "I\'m a yoga enthusiast. Passionate about learning and teaching .  Getting up early at 5 has changed my lifestyle more towards healthy being and it connects me to the nature. Looking forward to stay with this habit lifelong to achieve more than I thought \\ud83d\\ude00.", "contribution": "I can share details of Yogic lifestyle easy to adapt\\ud83d\\udcab\\ud83e\\udd18", "intro": "Yoga Trainer"}',
  '{"name": "Anusha Yeruva", "place": "Hyd, Telangana", "waNumber": "917036449449", "email": "anshuyeruva@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e45a8e63cc630139af3f0d8f9ef450a6d0f497d93bf5fc0b078d7605cfea4efe/Screenshot_20210521_195038_Photos_2.jpg", "scribbleSpace": "I am intrested in sustainable living. I love nature walks, take fresh air and organic diet, which helps me to be fit and healthy. I am looking forward to know myself and my intrests with the help of this club.", "contribution": "I can discuss on sustainabke living and its future benifit", "intro": "Yoga practitioner and a minimalist"}',
  '{"name": "Charuta", "place": "Sus road pune", "waNumber": "919822012622", "email": "charuta.gaikwad@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8b16a28c23303fd9c3838a669c2613f99868061dd90edc13206a5c89d0e179f9/IMG_20210318_225138.jpg", "scribbleSpace": "I am a Mirror, safe - trustworthy place to reflect yourself and feel powerful, free, beautiful, happier than before.", "contribution": "How to live the life at the fullest without expectations", "intro": "Life itself"}',
  '{"name": "Anu Prabhakar", "place": "BARC, Mumbai", "waNumber": "919820160969", "email": "prabhakaranu93@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ebebb56b65f4318d9b91334a195d44e96bf409bb27600dbe4442f809320d3d7c/IMG_20200412_WA0004.jpg", "scribbleSpace": "With the start of this program I want to be make finish maximum of my non office duties in morning such as exercise, yoga. \\nPlan to journal my daily morning update for these 21 days. \\nGoal to be more morning friendly", "contribution": "Maybe some exercise tips", "intro": "Ambivert, love nature, athlete"}',
  '{"name": "Siddharth Biniwale", "place": "Ganesh Mala, Sinhagad road Pune", "waNumber": "919405505902", "email": "sakhasiddha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/dbe063adf4940a04a78b4bd017942ef43a6bb0e79fc6c26ba4650772e9bbc491/IMG_20210630_071039.jpg", "scribbleSpace": "I am a Naturalist. Exploring wilderness and understanding ecosystems is a way of life for me. I Birdwatching, wildlife photography interests me a lot. I enjoy listening to the birds and recording bird calls. Currently working on a research project on Avian Bioacuostics (Sounds of birds) in different forest habitats.", "contribution": "a) Tips for wildlife/Bird photography\\n\\nb) DIY tricks to make Bird nests from trash", "intro": "Storyteller, Naturalist"}',
  '{"name": "Sharvari Patil", "place": "Baner Pune", "waNumber": "919766039388", "email": "sharvari.p@gmail.com", "photoLine": "https://api.typeform.com/responses/files/58eab976724715c3981c2f1b0805181e100b50bbae4f06ce143f1dd8a86226f8/img_4_1626544121665.jpg", "scribbleSpace": "I love exploring new places, meeting new people & spreading positivity.", "contribution": "My travelling experiences, my songs on keyboard & my short poems / shayris.", "intro": "Wanderlust & Photographer"}',
  '{"name": "Prachi Bogam", "place": "Harigram village, new panvel", "waNumber": "918655573501", "email": "prachi.bogam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f9f3572922c1d4660a1677d2cf2af6d957942cd3634303fa1199a2576f99e47c/IMG_20210728_122321.jpg", "scribbleSpace": "I am home maker now and since not doing any outside job whenever get time I got into the habbit of watching mobile so wanted to get rid of that and persue new habits of reading, walking etc.", "contribution": "I work in the field of early childhood education and language development.", "intro": "Eucationist"}',
  '{"name": "Preetee", "place": "Aundh, Pune", "waNumber": "919422517129", "email": "opreetee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f0089291d000657537ce06b45abe4469980c244e5feab05e46c6af4149206653/WhatsApp_Image_2021_06_26_at_23.20.25.jpeg", "scribbleSpace": "I am a book. I let the reader read me. Contents of the book: Meditation, Yog, Reading, Writing, Biodiversity, Gardening, Education, Parenting, Mindfulness, Water conservation, Soil conservation, Environment Education", "contribution": "Eco friendly parenting, Screen free parenting, Eating habits, Terrace Gardening, Lazy composting", "intro": "Nature observer"}',
  '{"name": "Reshma Jitesh", "place": "Kharghar", "waNumber": "919167762971", "email": "reshmajitish@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78c5b8b61d67f65d91adce12b808e63f32d4e6ae50abf541a5229d312318505f/IMG20210729194310.jpg", "scribbleSpace": "I love to dance, reading and a little social", "contribution": "Anything about dance", "intro": "I dance but not professional"}',
  '{"name": "Ashish Sharma", "place": "Malad East-Mumbai", "waNumber": "919619730766", "email": "ashish.at.iit@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9f0cb9e10c7165dcc7c7fa4dd5b212236ed3e4ff026eb363a0a2afe4da70fd02/1AB42CCD_8F6C_4D78_BB87_CF9D4EB9FA00.jpeg", "scribbleSpace": "Sports, outdoor activities, looking forward to regulate life", "contribution": "Not sure as of now.", "intro": "Yoga Practitioner"}',
  '{"name": "Shilpa Gaur", "place": "Makrana Rajasthan", "waNumber": "919794378287", "email": "shilpa020489@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78c34a36457b354f74fc0d396098e0aaa08f3e6d6e7f0c8746ea785584143142/E0D59C16_1EB9_45FC_9C1D_BB89F55CDE93.jpeg", "scribbleSpace": "Reading, Want to get fit and get more done everyday", "contribution": "Functionally I can help in marketing and strategy related things. I work with a gaming studio so anyone looking to understand about this area can connect with me. I can help buddy up with people in micro projects for a week ( like finishing a small online course or book in a week or small sprint like not eating junk etc). I can be a good responsibility partner.", "intro": "Habitual Reader"}',
  '{"name": "Smita Mehetre", "place": "Baner, Pune", "waNumber": "919890395931", "email": "rtsmita@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78b6dd4862fe15231a94b90ff0b39fef5c5f5052b14bcaa9c03a81d9e72677fc/IMG_20210511_WA0001.jpg", "scribbleSpace": "Practice Iyengar yoga, interested in full time farming, love slow sustainable lifestyle. Developing interest in reading.", "contribution": "I love to do handwork, sewing, crochet, making something out of waste. Made many small projects using left over fabric", "intro": "Sustainable living practitione"}',
  '{"name": "Gauri Limaye", "place": "Navi Peth Pune", "waNumber": "919371829077", "email": "gauri.gole@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fedaf5f29483c04ed325bd7a9292317499531af60ba81bb28ef79efbd70a1568/IMG_20210110_211420_146.jpg", "scribbleSpace": "I\'m interested in classical music and literature,also interested in language teaching. I love cooking and I am also very talkative person, love lots of people around me", "contribution": "About Marathi grammar, Indian classical music", "intro": "Classical vocalist,teacher"}',
  '{"name": "Shaila Patil", "place": "Satara", "waNumber": "919764663030", "email": "shailapatil306@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1a2f92494026dcf1a20243f3ca607bd823bad5668d098a07946b2a6d9464b0ff/IMG_20210726_WA0015.jpg", "scribbleSpace": "Interested in gardening.Love to spend time with nature Want to lead a healthy life.", "contribution": "NA", "intro": "Teacher"}',
  '{"name": "Shrravane Vuyyuru", "place": "Chennai", "waNumber": "919884983213", "email": "shrravane21@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ab2e8e825bf478608eb71b72409b71667270836ed5594bf4eb113f16f54a851b/C925DFB5_B1C3_45C0_AAF6_EA4C50BB6EEA.jpeg", "scribbleSpace": "Just another happy go lucky girl, finding new ways to become the best version of myself. Also, someone with an appetite for art.", "contribution": "Just looking forward to learn from you guys for now!", "intro": "CA aspirant!"}',
  '{"name": "Satyajit Shinde", "place": "Powai, Mumbai", "waNumber": "919869321812", "email": "shinde.satya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/570b5ef08fb1c97e6def281fc96be9bed6490eee96f7dd3c7066f103bc75e60f/20210214_164634.jpg", "scribbleSpace": "IntereseBirding, Wildlife Photography", "contribution": "Na", "intro": "Bird Photographer"}',
  '{"name": "Rashmi chand", "place": "Powai Mumbai", "waNumber": "918879248582", "email": "rashmichand17@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e155c46a6000667b760c83639dca9bc56ac3ecde67fc9c4137d7328e0ee11095/IMG_20210623_WA0027__01__01__01.jpg", "scribbleSpace": "Writing, reading, crafting and yoga", "contribution": "Not sure", "intro": "Content writer and blogger"}',
  '{"name": "Harshal (Shaad)", "place": "Majiwada Thane", "waNumber": "918369288435", "email": "aakarint@rediffmail.com", "photoLine": "https://api.typeform.com/responses/files/da4bc08f74a3e95e627c85b132e6fd85d8dc49a257a32409b8216ba1b6993592/Screenshot_20210721_161300_Photos_2.jpg", "scribbleSpace": "Interested in urdu language, learning the same. Want to be fit till the end. Live and Let Live is the moto of my life.", "contribution": "Knowledge about urdu language, shero shayari and of course about Interior Designing", "intro": "Interior Designer"}',
  '{"name": "Suyash Nagayach", "place": "Bavdhan, Pune", "waNumber": "919890005923", "email": "suyash.nagayach@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9d34718becfbb22cf350392afe2e89078ccc5f860c2c5f16ede299769db9d973/IMG_8329.JPG", "scribbleSpace": "I am avid sports lover, like reading books (mostly non-fiction) and photography is more than merely an interest. The biggest expectation in life is to continuosly become better version of self. Being a night owl all my life, I decided to take up the challenge to wake up early and whoa I have somehow conquered it and discovered about myself more than ever before. Nights are wonderful but believe me Mornings are pure bliss.", "contribution": "I can offer sharing different perspectives from the world of sports. Also, I would like sharing the philosophical poetry during the daily interactions.", "intro": "Pragmatic Believer"}',
  '{"name": "Jyoti Dalvi", "place": "Pashan Pune", "waNumber": "917030649290", "email": "jyoti_dalvi@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/b2432fb720e1cdcb1e6997f7038e02621ce8333094f7c83a1f956332a796b10c/20191008_230101.jpg", "scribbleSpace": "Love to do yoga.", "contribution": "I bake. I am into business. I have also been a Journalist.", "intro": "Yoga practitioner"}',
  '{"name": "Vinodh", "place": "Bangalore", "waNumber": "919986025927", "email": "vinodh.hana@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e9bddbd9a213cc61bd6042d2ea0fda949fd9d2beeabd57b3ed5cbccb5bcfbae5/IMG_20210622_112343.jpg", "scribbleSpace": "Digital Wellness Entrepreneur, sports ,book, music, Travel", "contribution": "Sports, wellness,books", "intro": "IT - Sports - Wellness"}',
  '{"name": "Vidya kedari", "place": "Katraj pune", "waNumber": "919850975989", "email": "vidya.ukedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/803a2ca75ea85ca70aa5f5e45eacf1e79976c8ccce562cdee8129aefc738b15b/20210619_151910.jpg", "scribbleSpace": "Yoga", "contribution": "Yes", "intro": "Yoga practitioner"}',
  '{"name": "Apurva Patil", "place": "Bhandarkar road", "waNumber": "919822552000", "email": "apurva.patil@spsoftindia.com", "photoLine": "https://api.typeform.com/responses/files/a716e209c56cdd28362a01642de4b56738e4c8d539f1243f128ea6c77afd502e/42EABA11_E919_4F77_9965_D330BCC95626.jpeg", "scribbleSpace": "Nothing to share as such. I am not able figure out", "contribution": "Good in public relation", "intro": "Home maker"}',
  '{"name": "Abhishek Anand", "place": "Delhi NCR", "waNumber": "918800373555", "email": "abhishek.x.anand@iiml.org", "photoLine": "https://api.typeform.com/responses/files/9035e146cb216cdb148787bbc0bdb03f58a48f1f14d29c68fd07eeb9ed32074a/Screenshot_20210101_214311_01.jpeg", "scribbleSpace": "Photography, reading. Love to explore the artistic side.", "contribution": "Will think through", "intro": "Love photography."}',
  '{"name": "Vikrant Patil", "place": "Kudawale, Dapoli", "waNumber": "918552969377", "email": "vikrant.patil@gmail.com", "photoLine": "https://api.typeform.com/responses/files/be4ea364bedc0b44c3d0200bc3da32197a5ee1b002bcb18a06bf746368fadd93/vikrant.jpeg", "scribbleSpace": "I can say that mostly I live life. During free time I teach programming, sometimes develop small scale software,  read, play music , help people live happy family life (counselling). As part of living life I spend a lot of time talking to my wife and my daughter, cook food, roam around the river, grow food in the small land that we have, maintain my mud house and trees that I have planted and wonder about the secrets of nature around me.", "contribution": "Health, food, yoga, Relationship Counselling", "intro": "A friend"}',
  '{"name": "Padma", "place": "Chennai", "waNumber": "919444078614", "email": "padmathiagarajan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d13157bc1f10044a791a66034d0115fc8c222ba173244197e12c86a2932c90a4/Snapchat_641092485.jpg", "scribbleSpace": "Art, fitness enthusiast, badminton, learning a new language, music", "contribution": "Sanskrit and learning from Bagavat Gita, Ramayana, Mahabarata", "intro": "Enthusiastic Learner"}',
  '{"name": "Tanvi sujit kolte", "place": "Dombivali", "waNumber": "919967771914", "email": "tskolte380@gmail.com", "photoLine": "https://api.typeform.com/responses/files/336516cffa3560bc30de4ad205100ff20382af7888a7babe7f72f41a371b3993/IMG_20210318_WA0013.jpg", "scribbleSpace": "Dance", "contribution": "Insurance", "intro": "Classical dancer, yoga teacher"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Yamini. D", "place": "Chennai", "waNumber": "919940112513", "email": "yaminiher@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d7abeca884983ab52711d698867adf0496f8aa6bd7f9178c90f68827001e49c2/20190914_155737.jpg", "scribbleSpace": "Fitness freak and nature lover", "contribution": "Positive thoughts", "intro": "Nature and bird lover"}',
  '{"name": "Madhura Rale", "place": "Aranyeshwar, Pune", "waNumber": "919860347975", "email": "madhura76@gmail.com", "photoLine": "https://api.typeform.com/responses/files/17aa005a61a9154c4472cff48550c4595d5df2d5316e2ef7844ac04d37fe13a5/Screenshot_20210101_193848_WhatsApp.jpg", "scribbleSpace": "A mad curious person", "contribution": "Not sure... currently in receiving mode", "intro": "Curious biochemist"}',
  '{"name": "Rahul Sharma", "place": "Sahakarnagar, Pune", "waNumber": "918390876388", "email": "rahul.symantec@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a21228e6f6004fdf5eb652c945c2ae0f1a37b252a23d6e0235997fbf7862a575/image.jpg", "scribbleSpace": "Have been working in IT field field since 15 years out of which I was working in Night shift (9:30 PM - 6:30 AM) for last 3 years. This seems to have messed up my body clock. While I am back to regular working hours  now, my expectation is to get into the habit of waking early and going to bed early.", "contribution": "Managing personal finances.", "intro": "People Leader"}',
  '{"name": "Sanika Surana", "place": "Lasur, Aurangabad", "waNumber": "919404377250", "email": "sanika9surana@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3d1ae8c6b449bb4adb5df7f7b94c5849040c83c1434f51d7992ece28390bc67d/IMG_20210628_011211.jpg", "scribbleSpace": "I am a psychology major student. I love to study. I love planning my day with the help of planners and colourful pens. I am inclined towards every hobby that is creative and healing in nature (doodling, painting, note making, skin care, fitness). I have been working a lot towards maintaining a good balance of things in life. I workout regularly, I eat healthy, I use natural products and now I look forward to better my sleep cycle. I also make a lot of skin care and hair care remedies at home. I am very bubbly and enthusiastic.", "contribution": "Positive psychology tips, doodling, skin care and hair care recipes, experiences, conversations on maintaining a healthy relationship with our loved ones, workouts, conversations about studying effectively.", "intro": "Psychology Student"}',
  '{"name": "Seema Kalantri", "place": "Bopal Ahmedabad", "waNumber": "919586499988", "email": "srkalantri@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/fadd501212aa1930e2e04bc7b35404df19751a1d6ab937ef24a8398715195934/IMG_20201212_082556.jpg", "scribbleSpace": "Learner", "contribution": "Good listening Ears", "intro": "Software Professional"}',
  '{"name": "prashant saraswat", "place": "raipur chhatisgarh", "waNumber": "917415315833", "email": "saraswat.nitrr@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6b735ceb72e0715819673e2ec6fab58cf555e188c87e66f36320a057fd607c40/IMG_20210721_WA0003.jpg", "scribbleSpace": "interested in reading", "contribution": "I just want to wake up early", "intro": "peace lover"}',
  '{"name": "Pritam Potghan", "place": "Moshi Pradhikaran, Pune", "waNumber": "919075002244", "email": "potghanpritam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/dde3eb33d351bbc38a3b8f227d6ce3a93b1df66564930ca5bd87fe24aacbba2d/IMG20201018173557.jpg", "scribbleSpace": "I love reading spiritual and self help books, writing in bullet journal, meditating. I like psychology and mythology. I am ambitious to be a Surgeon, a Doctor, to save lives.", "contribution": "The power of reading, Meditation, how to deal with depression, Guide to karma, study tips, things i do in my me time.", "intro": "Aspiring Medical student"}',
  '{"name": "Pallavi Dhoot", "place": "Pune, Senapati Bapat Road", "waNumber": "919822008007", "email": "pallavidhoot@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/481eb608bce182acc4df23d94aae588e4f8c34ad3e573cb78f2d5b430c9ec35e/16250665649071373088638.jpg", "scribbleSpace": "I am a homemaker mother.\\nA researcher by \\nnature; passionate about nature, environment, health and spirituality. I facilitate a Naturopathy Awareness and Practice Program by the name \'Healthy Me\'", "contribution": "I would love to share about health, nutrition, natural healing, medicine free life & about parenting", "intro": "An Avid Explorer"}',
  '{"name": "Kirteeraj Malkar", "place": "Rajarampuri, Kolhapur", "waNumber": "919405550489", "email": "kirteerajmalkar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5ae79c968ab63c7fab1958ae7e9074fa74236d0a05e09b2758160c8f7176ee4a/Screenshot_20200726_120311.png", "scribbleSpace": "i am passionate Developer, interested in sprituality, meditation and finding meaning of life.", "contribution": "I can share my favorite qoutes, I can share some hacks to wake up early & sleep well", "intro": "Developer"}',
  '{"name": "deepali chaubal", "place": "Bangkok", "waNumber": "66814589440", "email": "samdeep45@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a3a343e571255e103b4b5160fad28a78ef98a5ce67c8d7b59b2617b03e4a4e4b/WhatsApp_Image_2021_07_30_at_3.06.07_PM.jpeg", "scribbleSpace": "I am a people\'s person. I love travelling & storytelling", "contribution": "Story telling", "intro": "A Story Teller"}',
  '{"name": "Bhoomica Asnani", "place": "Thane Mumbai", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7e10c78d7ac5ecbec1e44dfefd08dd7e754012345f5fc9ad3a2c6ac68756cf93/IMG_20210328_190020.jpg", "scribbleSpace": "I am an academician by profession. I like meeting new people and learn more about the joys of being alive.", "contribution": "Experiences of being an academician. Difficult teenagers, lazy youngsters, helicopter parents", "intro": "Seasoned Woman"}',
  '{"name": "Ajay Kumar Dadi", "place": "Warangal", "waNumber": "918897755822", "email": "ajaykumar.dadi2103@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c4bb585c2f8a2ae2dce8a6d8191dffcafded5972df421a8b48dd11dea457fd4a/IMG_20191124_180757.jpg", "scribbleSpace": "I love thriller and horror movies. Looking for excitement in every aspect of my life.", "contribution": "How to make our lives easy", "intro": "Natural lover"}',
  '{"name": "Poonam Mandke", "place": "Aundh Pune", "waNumber": "919970921230", "email": "poonammandhyani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b66c5a06e25058417b5734fd576d53c4035a3865a07cf274fc723fe26ca3865d/IMG_20210717_095819.jpg", "scribbleSpace": "I like music and dance, like being healthy and get people healthy at mind body and soul. I wish to help India get more healthy and generate more work opportunities for women.", "contribution": "I can help upcoming entrepreneurs with brainstorming for ideas or execution plan and strategy. I can help being a buddy to help reach some goals. Basically accountability partner.", "intro": "Health buddy"}',
  '{"name": "Rajeev Nikumbh", "place": "Goregaon, Mumbai", "waNumber": "919167050101", "email": "rajeevnikumbh@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4a6d9c0eb3af117b7540d7aa87ea2e34c7e4b4bdcf856f5ce68483e7ff492994/IMG_6962.jpg", "scribbleSpace": "A sports enthusiast. Love playing and watching volleyball, football & cricket. Any outdoor activities including trekking, exploring new places are always fun. And yes, good movies and music are definitely a best way to enjoy leisure. An investment banker during the day to earn bread and butter. Though I am not a morning person and becoming the one has been wishful thinking since ages.  So, here I am...Very excited...looking forward to meet you all amazing people and lets together throw off the shackles :)", "contribution": "I am happy to talk about sports - technique and game plans and sports motivation. And Lend my professional experience if it could be of any help.", "intro": "Sports enthusiast"}',
  '{"name": "Preethi", "place": "Bangalore", "waNumber": "917406000927", "email": "preethi1991g@gmail.com", "photoLine": "https://api.typeform.com/responses/files/40186777333ba5429ce3ed94faa8256728bc18f65676185b51bf922fcd10f327/IMG_20210630_175413.jpg", "scribbleSpace": "Music, cooking, content creator", "contribution": "No", "intro": "Wellness Enthusiast , Coach, Momprenuer"}',
  '{"name": "Sandeep Karande", "place": "Brahmand, Thane", "waNumber": "919619903224", "email": "karandesandeep@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f4b2a91f5b54c36292644395990d8212b5d288584c594bc2c2af88fe5003968a/DSC_9324.jpg", "scribbleSpace": "I enjoy trekking, bikes rides and nature conservation", "contribution": "How treks can help you explore a new self.", "intro": "Nature Lover"}',
  '{"name": "Pranav Patil", "place": "Parvati Pune", "waNumber": "919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fc949309ee0e158a2e044e0a403d861dcd7e48f6f6869dc5f0ee51124395bd9f/Screenshot_20210624_162326__01.jpg", "scribbleSpace": "Psychologist body-builder, INFJ, Counsellor-Mentor-Advisor \\ud83d\\ude07, Scientist, Astrology-curious, Work-out-holic \\ud83d\\udcaa, Nasha-positive person", "contribution": "1. How to be happy 2. Micro- workouts at home 3. Make good tea 4. Socializing tips 5. Tinker on piano", "intro": "Armwrestling enthusiast"}',
  '{"name": "Paras Chhabra", "place": "New Delhi", "waNumber": "919999656662", "email": "paraschhabra96@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b44e03ec40a118aa68550ad82ef06ea7cf8127d29a4deaea3cd50a22e9c5c7aa/20210123_075025.jpg", "scribbleSpace": "Learning yoga and on the path to understand spirituality.", "contribution": "Podcasting for beginners", "intro": "Software Engineer"}',
  '{"name": "Kavitha Reddy", "place": "Hyderabad", "waNumber": "919177063544", "email": "yeruvakavithareddi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3bea44dda7357c6d900eec31029c3507c037e162824c51603e3a38d2267e5595/Screenshot_2021_0630_190954.png", "scribbleSpace": "Interested in knowing new things, like playing sports, good @cooking", "contribution": "Can share life experience s", "intro": "Interested in knowing new things, like playing sports, good @cooking"}',
  '{"name": "Umesh Kedari", "place": "Katraj Pune", "waNumber": "919850355691", "email": "umesh.kedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72e716e345216a58fdd9f46ef50ef577fba33868fb139f3cea7bbaeb285ae1e1/IMG_20200521_094042.jpg", "scribbleSpace": "Listening Indian classical music, Gardening", "contribution": "Experience", "intro": "Mechanical Engineer"}'
]


var takenOut = [
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  ]